body {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  background-color: var(--whiteEF);
}

* {
  box-sizing: border-box;
}

.tooltip:hover::before {
  display: none;
}

.device-width {
  width: 90%;
  max-width: 1700px;
  display: flex;
  /* border: 1px solid; */
  margin-left: auto;
  margin-right: auto;
  justify-content: space-evenly;
}

.AppBody {
  width: 100%;
}

.AppLeftSide,
.MainBodyContent,
.AppRightSide {
  background-color: var(--white);
}

.AppLeftSide,
.AppRightSide {
  width: 20%;
  min-width: 200px;
  position: sticky;
  top: 80px;
}

.AppLeftSide {
  height: 500px;
  min-height: 400px;
  max-height: 90vh;
  display: block !important;
}

.AppLeftSide.show {
  display: block !important; /* Show when the screen is wider than 890px */
  /* transform: scaleX(1); */
}

.AppLeftSide .sidebar-close-btn {
  display: none;
}

.AppRightSide {
  height: fit-content;
  margin-top: 150px;
}

.MainBodyContentSpace {
  height: 150px;
}

.MidBody {
  width: 50%;
  min-width: 320px;
}

.MainBodyContent {
  min-height: 500px;
  margin-bottom: 100px;
  padding-bottom: 50px;
}

.banner-image {
  width: 100%;
  height: 100px;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  background-color: var(--lightBlue);
}

.banner-image .image-container {
  width: 140px;
  height: 140px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  border-radius: 50%;
  background-color: var(--white);
}

.image-container .image-shadow-animation,
.image-container .image-outer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}

.image-shadow-animation {
  top: 0; left: 0;
  position: absolute;
  box-shadow: -4px 5px 10px #97E7E1;
  animation: rotator infinite 3s linear;
}

@keyframes rotator {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.image-container .image-outer {
  background-color: var(--white);
}

.banner-image .image-outer img {
  margin-top: -20px;
  position: relative;
  z-index: 10;
}

.profile-name {
  margin-top: 70px;
  font-size: 22px;
}

.left-category {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -10px;
}

.left-category p {
  margin: 0;
  font-size: 12px;
  padding: 2px 1px;
  color: #FFFFFF;  
  text-align: center;
  background-color: var(--shineBlue); 
}

.left-social-container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  gap: 7px;
  justify-content: center;
  align-items: center;
}

.left-social-container .social {
  width: 30px;
  height: 30px;
  display: flex;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  border-radius: 50%;
  padding-bottom: 1px;
  transition: all .2s;
  align-items: center;
  justify-content: center;
  color: var(--darkBlue);
  border: 1px solid var(--darkBlue);
}

.left-social-container .social:hover {
  color: var(--white);
  border-color: var(--darkBlue);
  background-color: var(--darkBlue);
}

.left-divider {
  width: 60%;
  border: .1px solid;
  border-color: var(--lightBlue);
}

.left-contact {
  width: 100%;
}

.left-contact ul {
  padding: 0;
  list-style: none;
}

.left-contact ul li {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  margin: 3px 10px;
  padding: 0 0 3px 0;
  border-bottom: 1px solid var(--whiteD2);
}

.left-contact ul li:nth-child(3) {
  align-items: flex-start;
}

.left-contact ul li .contact-icon-div {
  border: 1px solid var(--darkBlue);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  border-radius: 3px;
  background-color: var(--darkBlue);
  color: var(--white);
}

.left-contact ul li p {
  margin: 0;
  padding: 0;
}

.left-cv-div {
  text-align: center;
  margin-top: 20px;
}

.left-cv-div a,
.phone-download-button a {
  padding: 7px 20px;
  color: var(--white);
  background-color: var(--darkBlue);
  border-radius: 3px;
}

.phone-download-button {
  position: fixed;
  right: 20px;
  bottom: 85px;
  z-index: 101;
}

.phone-download-button a {
  opacity: .6;
  border-radius: 20px;
}

.phone-download-button a:hover {
  opacity: 1;
}

.right-menu {
  padding: 10px;
}

.right-menu h3 {
  margin: 0;
  font-weight: 600;
}

.right-menu ul {
  padding: 0;
  list-style: none;
}

.right-menu ul li a {
  width: 100%;
  margin: 5px 0;
  padding: 5px 0;
  display: block;
  color: var(--black);
  font-size: 14px;
  text-align: center;
  position: relative;
  border-radius: 5px;
  text-decoration: none;
  transition: all .2s;
  background-color: var(--whiteEF);
}

.right-menu .active,
.right-menu ul li a:hover {
  color: var(--white);
  background-color: var(--darkBlue);
}

.right-menu ul li .active::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 10%;
  height: 80%;
  width: 3px;
  background-color: var(--white);
  border-radius: 3px;
  display: block;
}

.largeDeviceMenu {
  display: block;
}

.smallDeviceMenu {
  display: none;
}

/* Mid Section */
.content-head-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.content-head-container h1 {
  margin: 7px 0;
  font-weight: 600;
  font-size: 30px;
  color: var(--red);
}

.content-head-container .head-line {
  width: 120px;
  height: 4px;
  border-radius: 5px;
  background-color: var(--red);
}

.about-para {
  padding: 0 5px;
  line-height: 20px;
  color: var(--blackPara);
}

.service-container {
  margin: 10px 0;
  padding: 5px 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.service-section {
  width: 48%;
  height: auto;
  border-radius: 15px;
  margin-bottom: 10px;
  border: 1px solid var(--shineBlue);
  transition: all .1s;
  padding: 10px;
}

.service-title {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--shineBlue);
}

.service-title .service-icon {
  font-size: 30px;
}

.service-title h2 {
  margin: 0;
  padding: 10px 0;
}

.service-section > p {
  padding: 0 5px;
  margin: 7px 0;
  color: var(--blackPara);
}

/* Portfolio Section */
.tab-wrap ul {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin: 0;
  margin-right: 10px;
}

.tab-wrap {
  position: relative;
  padding: 0;
}

.tab-wrap input[type='radio'] {
  display: none;
}

.tab-wrap label {
  display: inline-block;
  color: #9A9A9A;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  transition: color 0.2s;
}

.tab-wrap .tab-content {
  position: absolute;
  top: calc(100% + 20px);
  left: 0;
  width: 100%;
  display: none;
  opacity: 0;
  border-top: 2px solid var(--orange);
  transition: opacity 0.15s linear;
}

.tab-wrap input[id^=cdh-services-tab]:checked + label {
  color: var(--darkBlue);
}

.tab-wrap input[id^=cdh-services-tab]:checked ~ .tab-content {
  opacity: 1;
  display: block;
  z-index: 5;
}

.tab-content {
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
}

.project-container {
  width: 48%;
  height: 150px;
  box-shadow: -2px 5px 10px -5px var(--blackPara);
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
}

.project-container > img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.project-container .hover-detail {
  position: absolute;
  bottom: 0; left: 0;
  gap: 10px;
  width: 100%;
  opacity: 0;
  color: var(--red);
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all .2s;
  background-image: linear-gradient(to top, var(--blackPara), transparent);
}

.project-container:hover .hover-detail {
  opacity: 1;
}

.project-container .hover-detail button {
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: transparent;
  transition: all .1s;
}

.project-container .hover-detail button:hover {
  color: var(--darkBlue);
}

.fullscreen-image-container {
  position: fixed;
  top: 0; left: 0;
  z-index: 99;
  width: 100%;
  height: 100vh;
  background-color: #00000088;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s;
}

.fullscreen-image-container > button {
  position: absolute;
  top: 25px;
  right: 30px;
  width: 30px;
  height: 30px;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--red);
  background-color: transparent;
  font-size: 30px;
  color: var(--red);
  transition: all .1s;
}

.fullscreen-image-container > button:hover {
  color: var(--white);
  border-color: var(--red);
  background-color: var(--red);
}

.fullscreen-image-div {
  width: 80%;
  height: 85vh;
  border-radius: 5px;
}

.fullscreen-image-div img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

/* Resume Section */
.strong-head {
  font-size: 17px;
  font-weight: 500;
  color: #333;
  margin: 0;
  margin-left: 4px;
}

.cert-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.certificate-box {
  width: 48%;
  height: auto;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid var(--shineBlue);
}

.certificate-box > h2 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--shineBlue);
}

.certificate-box > h2 span {
  font-size: 10px;
  font-weight: 400;
  color: var(--yellow);
}

.certificate-box .bar {
  width: 60%;
  border: none;
  height: 2px;
  margin-top: 5px;
  background-color: var(--shineBlue);
}

.certificate-box > p {
  color: #888;
}

.contact-container {
  width: 100%;
}

.contact-container form {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.contact-container form {
  display: flex;
  flex-direction: column;
}

.contact-container form * {
  transition: all .2s;
  font-family: 'Jost', sans-serif;
}

.contact-container form > div input {
  width: 49%;
}

.contact-container form input,
.contact-container form textarea {
  width: 100%;
  outline: none;
  font-size: 13px;
  padding: 7px 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  color: var(--blackPara);
  border: 1px solid var(--shineBlue);
  box-shadow: -1px 2px 5px -2px var(--blackPara);
}

.contact-container form input:focus,
.contact-container form textarea:focus {
  border-color: var(--darkBlue);
}

.contact-container form input::placeholder,
.contact-container form textarea::placeholder {
  color: #00000088;
}

.contact-container form textarea {
  resize: none;
  height: 120px;
}

.contact-container form button {
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  padding: 7px 10px;
  border-radius: 3px;
  color: var(--white);
  border: 1px solid transparent;
  transition: all .2s;
  background-color: var(--darkBlue);
  box-shadow: -1px 2px 5px -1px var(--blackPara);
}

.contact-container form button:focus,
.contact-container form button:hover {
  color: var(--darkBlue);
  border-color: var(--darkBlue);
  background-color: transparent;
}

.contact-container ul {
  list-style: none;
}

.contact-container ul li {
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: var(--blackPara);
}

.contact-container ul li div:nth-child(1) {
  font-size: 17px;
  color: var(--yellow);
}

.contact-container > ul:nth-child(2) {
  border: 1px solid;
  margin-right: 50px;
}

.phone-contact-details {
  display: none;
}

.phone-profile-icon {
  display: none;
}

.copyright-section {
  width: 100%;
  text-align: center;
  margin-top: -70px;
  margin-bottom: 100px;
}

.copyright-section p {
  width: 100%;
  text-align: center;
  color: var(--blackPara);
  opacity: .8;
}

iframe {
  margin-top: 50px;
  box-shadow: -2px 3px 7px var(--blackPara);
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1100px) {
  .service-title h2 {
    padding: 0;
  }
  .service-section p,
  .certificate-box > p {
    font-size: 12px;
  }
  .left-contact ul li p {
    font-size: 11px;
  }
  .left-contact ul li .contact-icon-div {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .device-width {
    width: 100%;
  }
  .certificate-box > h2 span {
    display: none;
  }
}

@media screen and (max-width: 870px) {
  .tooltip:hover::before {
    display: block;
  }
  
  .device-width {
    position: static;
  }

  .AppLeftSide {
    position: fixed;
    top: 0; left: 0;
    z-index: 100;
    height: 88vh;
    display: none !important;
    /* transform: scaleX(0);
    transform-origin: top left; */
  }
  
  .right-menu ul li .active::after {
    display: none;
  }

  .phone-profile-icon {
    z-index: 101;
    position: fixed;
    top: 30px;
    left: 30px;
    width: 45px;
    height: 45px;
    border: 1px solid var(--shineBlue);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
  }

  .phone-profile-icon img {
    width: 110%;
    height: 110%;
    object-fit: cover;
  }
  
  .AppRightSide {
    position: fixed;
    left: 0; bottom: 0; top: auto;
    width: 100%;
    border-radius: 0 !important;
    margin-top: 0;
    z-index: 101;
  }
  
  .right-menu h3 {
    display: none;
  }
  
  .MainBodyContentSpace {
    height: 70px;
  }
  
  .right-menu ul {
    margin: 0;
    gap: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
  }
  
  .right-menu ul li {
    width: 100%;
  }

  .right-menu ul li a {
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MidBody {
    width: 80%;
  }
  
  .fullscreen-image-div {
    height: 400px;
  }

  .largeDeviceMenu {
    display: none;
  }

  .smallDeviceMenu {
    display: block;
  }

  .phone-contact-details {
    display: block;
  }

  .phone-profile-icon {
    display: block;
  }
}


@media screen and (max-width: 500px) {
  .left-contact {
    width: 95%;
    margin: 10px auto 30px auto;
  }

  .MainBodyContent {
    min-height: 880px;
  }
  
  .service-section {
    width: 80%;
    min-height: 170px;
  }

  .certificate-box {
    width: 80%;
  }
  
  .project-container {
    width: 100%;
    height: 220px;
  }
  
  .content-head-container h1 {
    font-size: 22px;
  }

  .contact-container form > div input {
    width: 100%;
  }
}