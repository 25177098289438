:root {
    --white: #FFFFFF;
    --black: #000000;
    --blackPara: #555555;
    --whiteD2: #D2D2D2;
    --whiteEF: #EFEFEF;
    --lightBlue: #8B93FF;
    --darkBlue: #5050FF;
    --shineBlue: #97E7E1;
    --imageOuter: #41C9E2;
    --gray: #50727B;
    --red: #FF204E;
    --yellow: #FFC94A;
}

::selection {
    color: var(--white);
    background-color:#FF204E88;
}

a {
    color: var(--black);
    text-decoration: none;
}

.tooltip {
    position: relative;
}

.tooltip:hover::before {
    content: attr(data-tooltip);
    position: absolute;
    top: -105%;
    color: var(--white);
    font-size: 13px;
    background-color: var(--imageOuter);
    padding: 2px 5px;
    border-radius: 3px;
}

.black-para {
    color: var(--blackPara);
}

.w-50 {
    width: 50%;
}
.w-80 {
    width: 80%;
}
.w-90 {
    width: 90%;
}
.w-95 {
    width: 95%;
}
.w-100 {
    width: 100%;
}

.h-100vh {
    height: 100vh;
}

.f-11 {
    font-size: 11px;
}
.f-13 {
    font-size: 13px;
}
.f-15 {
    font-size: 15px;
}
.f-17 {
    font-size: 17px;
}
.f-18 {
    font-size: 18px;
}
.f-19 {
    font-size: 19px;
}
.f-20 {
    font-size: 20px;
}
.f-22 {
    font-size: 22px;
}
.f-23 {
    font-size: 23px;
}
.f-25 {
    font-size: 25px;
}
.f-27 {
    font-size: 27px;
}
.f-30 {
    font-size: 30px;
}
.f-35 {
    font-size: 35px;
}
.f-40 {
    font-size: 40px;
}
.f-45 {
    font-size: 45px;
}
.f-50 {
    font-size: 50px;
}
.f-60 {
    font-size: 60px;
}

.m-0 {
    margin: 0;
}
.m-1 {
    margin: 2px;
}
.m-2 {
    margin: 4px;
}
.m-3 {
    margin: 6px;
}
.m-4 {
    margin: 8px;
}
.m-5 {
    margin: 10px;
}

.mt-1 {
    margin-top: 2px;
}
.mt-2 {
    margin-top: 4px;
}
.mt-3 {
    margin-top: 6px;
}
.mt-4 {
    margin-top: 8px;
}
.mt-5 {
    margin-top: 10px;
}

.mx-1 {
    margin-left: 2px;
    margin-right: 2px;
}
.mx-2 {
    margin-left: 4px;
    margin-right: 4px;
}
.mx-3 {
    margin-left: 6px;
    margin-right: 6px;
}
.mx-4 {
    margin-left: 8px;
    margin-right: 8px;
}
.mx-5 {
    margin-left: 10px;
    margin-right: 10px;
}

.my-1 {
    margin-top: 2px;
    margin-bottom: 2px;
}
.my-2 {
    margin-top: 4px;
    margin-bottom: 4px;
}
.my-3 {
    margin-top: 6px;
    margin-bottom: 6px;
}
.my-4 {
    margin-top: 8px;
    margin-bottom: 8px;
}
.my-5 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.p-0 {
    padding: 0;
}
.p-1 {
    padding: 2px;
}
.p-2 {
    padding: 4px;
}
.p-3 {
    padding: 6px;
}
.p-4 {
    padding: 8px;
}
.p-5 {
    padding: 10px;
}

.pt-1 {
    padding-top: 2px;
}
.pt-2 {
    padding-top: 4px;
}
.pt-3 {
    padding-top: 6px;
}
.pt-4 {
    padding-top: 8px;
}
.pt-5 {
    padding-top: 10px;
}

.px-1 {
    padding: 0 2px;
}
.px-2 {
    padding: 0 4px;
}
.px-3 {
    padding: 0 6px;
}
.px-4 {
    padding: 0 8px;
}
.px-5 {
    padding: 0 10px;
}

.py-1 {
    padding-top: 2px;
    padding-bottom: 2px;
}
.py-2 {
    padding-top: 4px;
    padding-bottom: 4px;
}
.py-3 {
    padding-top: 6px;
    padding-bottom: 6px;
}
.py-4 {
    padding-top: 8px;
    padding-bottom: 8px;
}
.py-5 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.d-none {
    display: none;
}

.flex {
    display: flex;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.justify-end {
    display: flex;
    justify-content: end;
}

.items-center {
    display: flex;
    align-items: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.space-around {
    display: flex;
    justify-content: space-around;
}

.space-evenly {
    display: flex;
    justify-content: space-evenly;
}

.align-center {
    text-align: center;
}

.border {
    border: 1px solid;
}
.border-right {
    border-right: 1px solid;
}
.border-bottom {
    border-bottom: 1px solid;
}
.border-left {
    border-left: 1px solid;
}

.shadow {
    box-shadow: 0 0 10px #00000033;
}

.radius-3 {
    border-radius: 3px;
}

.radius-5 {
    border-radius: 5px;
}

.radius-10 {
    border-radius: 10px;
}

.radius-15 {
    border-radius: 15px;
}

.radius-20 {
    border-radius: 20px;
}

input::-webkit-search-cancel-button {
    display: none;
}

img {
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
}

.fixed {
    position: fixed;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.model {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    transform: scale(0);
}

.model-content {
    width: 100%;
    height: 100%;
    background-color: #26262655;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.model-container {
    width: 550px;
    height: auto;
    padding: 35px 20px 20px 20px;
    position: relative;
    border-radius: 10px;
    background-color: white;
    transform: scaleY(0);
    transform-origin: top;
    transition: all .3s ease;
}

.btn-model-close {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 10px;
    right: 10px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    font-size: 17px;
    color: red;
}

.model-body {
    width: 97%;
    max-height: 500px;
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
}

/* Scrollbars */
.model-content::-webkit-scrollbar {
    display: none;
}

.model-body::-webkit-scrollbar {
    width: 7px;
    background-color: transparent;
}

.model-body::-webkit-scrollbar-track {
    width: 100%;
    background-color: #EEEEEE;
    border-radius: 10px;
}

.model-body::-webkit-scrollbar-thumb {
    background-color: #DDDDDD;
    border-radius: 10px;
}

.model-body::-webkit-scrollbar-thumb:hover {
    background-color: #BBBBBB;
}

@media only screen and (max-width: 700px) {
    .model-container {
        width: 80%;
    }    
}